/* pagination */
.pagination {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 1px;
}

@media screen and (min-width: 768px) {
  .pagination {
    gap: 20px;
  }
}

.pagination .page-number {
  font-size: 16px;
  font-weight: 600;
  color: #0e7490;
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.pagination .page-number:hover {
  color: #fff;
  background: #0e7490;
}

.pagination .active {
  color: #fff;
  background: #0e7490;
}

.pagination .active:hover {
  color: #0e7490;
  background: #fff;
}

.disabled {
  opacity: 0.5;
}

.pagination .disabled a:hover {
  cursor: auto;
  color: #00a8ff;
  background: #fff;
}

/* spinner */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #003f72;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #003f72 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hide Show More - Filter Lists */
.filter-list div.filter-item:nth-child(n + 7) {
  display: none;
}

.filter-list-hide {
  display: none;
}
